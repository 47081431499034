
import { get, post } from "@/api/axios";
import TableRootEntities from "@/components/TableRootEntities.vue";
import { Component, Vue } from "vue-property-decorator";
import { IExaGroup } from "../../state/models";

@Component({
    components: {
        "table-root-entities": TableRootEntities,
    }
})
export default class ExaGroupsView extends Vue {
    groups: Array<IExaGroup> = [];
    fields: Array<{ icon: string, field: string }> = [
        { icon: "mdi-clock", field: "name" },
        { icon: "mdi-clock", field: "users_length" },
    ];
    headers: Array<any> = [
        {
            text: "Имя",
            align: "start",
            value: "name"
        },
        {
            value: 'actions',
            align: 'end'
        }
    ];
    search: string = "";

    dialog = {
        isActive: false,
        name: ''
    }

    caption(item: IExaGroup) {
        return item.name;
    }

    mounted() {
        get(`/api/@examination/exa/group`)
            .then((resp_json) => {
                console.log(resp_json);
                this.groups = resp_json.data.map((x: any) => {
                    x.users_length = x.users.length.toString();
                    return x;
                });
            });
    }
    async createEmptyGroup() {
        let name = this.dialog.name;
        console.log(`createEmptyGroup ${name}`);

        const result = (await post(`/api/@examination/exa/group/${name}`, {})).data as IExaGroup;
        this.groups.push(result);
        this.dialog.isActive = false;
    }


    goToGroup(item: IExaGroup) {
        this.$router.push({ name: 'examination_group', params: { id: item._id } })
    }
}
